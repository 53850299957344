.button {
  background: rgb(100, 168, 248);
  color: #000000;
  width: 400px;
  font-size: 32px;
  padding: 5px;
  border: none;
  transition: all 0.4s;
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 20px;
  font-weight: 700;
  &:hover {
    background-color: #79e730;
  }

  @media screen and (max-width: 720px) {
    width: 320px;
  }

  @media screen and (max-width: 600px) {
    width: 300px;
    font-size: 30px;
  }
  @media screen and (max-width: 500px) {
    width: 220px;
    font-size: 24px;
  }
}

.button.rounded {
  border-radius: 32px;
}
