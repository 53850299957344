@import "./style/index.scss";

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}
a {
  text-decoration: none;
  color: #ffffff;
}

.text-white {
  color: white;
}

.text-grey {
  color: #cccccc;
}
