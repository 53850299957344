.page-admin {
  background-color: blanchedalmond;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .connect-btn {
    height: 100%;
    font-size: 25px;
    border-radius: 32px;
    border: none;
    cursor: pointer;
    transition: all 0.4s;
    width: 250px;
    height: 60px;
    font-weight: 700;
    color: #000dff;
    background-color: #cccccc;

    &:hover {
      background-color: #e78130;
      color: white;
    }
  }

  .whitelist {
    background-color: #131313;
    width: 400px;
    padding: 20px;
    border-radius: 8px;
    max-height: 800px;
    display: flex;
    flex-direction: column;

    .add-field {
      width: 100%;
      display: flex;
      margin-bottom: 20px;

      .add-btn {
        cursor: pointer;
      }

      input {
        font-size: 17px;
        flex: 1;
        outline: none;
      }
    }

    .content {
      color: #ffffff;
      margin-bottom: 20px;
      overflow: auto;

      .address-line {
        display: flex;
        justify-content: space-between;

        .remove-btn {
          cursor: pointer;
        }
      }
    }

    .confirm-btn {
      cursor: pointer;
      font-size: 20px;
    }
  }

  .text {
    font-size: 20px;
    text-align: center;
    font-weight: 700;
  }
}
