.component-navbar {
  .navigation {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 80vw;
    margin: auto;
  }
  .mint-btn {
    background-color: #ffffff;
    color: #000000;
    text-decoration: none;
    margin-left: auto;
    padding: 8px 32px;
    border-radius: 20px;
    background-color: #fff;
    color: #000;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 3px;
    font-weight: 700;
  }
}
