.page-mint {
  min-height: 100vh;
  background: url("../../assets/background.jpg") 100% top / 100% 130%;

  .card-wrapper {
    margin-top: 50px;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 750px) {
      margin-top: 0px;
    }
  }

  .card {
    background-image: linear-gradient(to right, #2c2b31, #202126);
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 20px;

    @media screen and (max-width: 750px) {
      flex-direction: column;
    }

    .nft-img {
      background: white;
      border: 10px solid #d73a3a;
      border-radius: 15px;
      position: relative;
      .nft {
        width: 400px;
        @media screen and (max-width: 845px) {
          width: 350px;
        }
        @media screen and (max-width: 750px) {
          width: 450px;
        }
        @media screen and (max-width: 500px) {
          width: 300px;
        }
        @media screen and (max-width: 300px) {
          width: 100%;
        }
      }
    }

    .right {
      position: relative;
      width: 100%;
      padding: 10px;
      @media screen and (min-width: 300px) {
        width: 300px;
      }
      @media screen and (min-width: 500px) {
        padding: 20px;
        width: 450px;
      }
      @media screen and (min-width: 750px) {
        width: 450px;
      }
    }

    .amount-input {
      position: relative;
      display: flex;
      align-items: center;
      flex-basis: calc(100% - 100px);
      min-width: calc(100% - 100px);
      button {
        position: absolute;
        background: none;
        border: none;
        color: white;
        font-size: 20px;
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);
      }
      input {
        width: 100%;
        text-align: center;
        background-image: linear-gradient(to right, #2a2d32, #22252a);
        border: none;
        padding: 10px;
        color: white;
        border-radius: 8px;
        outline: none;
        font-size: 20px;
      }
      .plus-btn {
        right: 5px;
      }
      .min-btn {
        left: 5px;
      }
    }

    .balance-wrapper {
      flex-basis: 100px;
      min-width: 100px;
      height: 100px;
      .label {
        text-align: center;
        height: 50%;
        padding-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 20px;
      }

      .balance {
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 22px;
        padding-bottom: 5px;
      }
    }

    .mint-btn-wrapper {
      background-color: #b9423c;
      padding: 3px 3px 8px;
      border-radius: 5px;
      margin-top: 10px;
    }

    .mint-btn {
      width: 100%;
      height: 100%;
      font-size: 25px;
      border: none;
      cursor: pointer;
      transition: all 0.4s;
      padding: 3px 0 5px;
      color: #ffffff;
      letter-spacing: 3px;
      border-radius: 5px;
      background-color: #fe5c51;

      &:hover {
        background-color: #e78130;
        color: white;
      }
    }

    .price-wrapper {
      display: flex;
      flex-wrap: wrap;
      font-size: 20px;
      letter-spacing: 2px;
      background-color: #2e2d33;
      border-radius: 5px;
      padding: 5px;
      margin-top: 10px;

      @media screen and (max-width: 500px) {
        letter-spacing: 1px;
      }

      .calculating-price {
        padding: 10px;
        background-color: #292a2e;
        border-radius: 5px;
        .excluding-gas-fee {
          display: flex;
          align-items: center;
        }
        flex-basis: 100%;
        @media screen and (min-width: 500px) {
          flex-basis: 50%;
        }
      }

      .total-price {
        padding: 10px;
        .price {
          display: flex;
          align-items: center;
          height: 40px;
        }
        flex-basis: 100%;
        @media screen and (min-width: 500px) {
          flex-basis: 50%;
        }
      }
    }
  }
}
